import XwLog from '@xwfe/web-track-browser'
let xwInstance: any = null;
// eslint-disable-next-line no-undef
const initXWLog = () => {
	if (xwInstance) return xwInstance
	// xw-log
	const logOpts = {
		logstore: 'aidoutang-activity-h5', // 日志库
		business_type: 'aidou-offical-website', // 项目唯一标识，todo:需要改成自己的项目名称哦
	}
  xwInstance = new XwLog(logOpts)
  xwInstance.setSysData({env_type: process.env?.NODE_ENV === 'production' ? 'production' : 'development'})
	return xwInstance
}

// 发送埋点
export function sendLog(id: string, params = {}) {
	let xwlog = initXWLog()
	if (!id) return
	xwlog.sendImmediate({
		click_id: id,
		...params
	})
}
