import React, { useEffect } from 'react'
import yingyezhizhao from '../../assets/images/yingyezhizhao.png'
import './style.less'

function YingYe() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="yingye">
      <img className="normal" alt="营业执照" src={yingyezhizhao} />
    </div>
  )
}
export default YingYe
