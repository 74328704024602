import React from 'react'
import ReactDOM from 'react-dom/client'
import '@until/rem'
import { RouterProvider } from 'react-router-dom'
import router from '@router/index'
import { Provider } from 'react-redux'
import { store } from '@model/store'
import ErrorBoundary from '@component/ErrorBoundary'
import { sendLog } from '@until/slsLog'
import './style/index.less'

// 注册全局日志方法
window._sendLog = sendLog

const rootDom = document.getElementById('root')

if (rootDom) {
  const root = ReactDOM.createRoot(rootDom)
  root.render(
    <ErrorBoundary>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </ErrorBoundary>
  )
}
