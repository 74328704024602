import React,{useEffect} from "react";
import { useMediaQuery } from 'react-responsive';
import {MEDIA_WIDTH} from'@consts/index'
import "./style.less";

function Yinsi() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isNotMobile=useMediaQuery({ minWidth: MEDIA_WIDTH.mobile })
  return (
    <div className={isNotMobile?'yinsi pc':'yinsi mobile'}>
      <div className="container">
<p className="title">
<span className="font-size-normal">用户个人信息保护政策</span>
</p>
<p>
<span className="font-size-normal">版本生效日期：2022年10月20日</span>
</p>
<p>
<span className="font-size-normal">版本更新日期：2022年10月20日</span>
</p>
<p>
<span className="font-size-normal">本《用户个人信息保护政策》（以下简称“本政策”）适用于融趣旗下所有的产品及服务（以下简称“融趣”或“平台”），您通过平台的产品及服务而接入的其他第三方产品或服务除外。请您务必在点击同意本政策之前，审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体标识，您应重点阅读。如您对政策有任何疑问，可按照本政策列明方式与融趣取得联系。</span>
</p>
<p>
<span className="font-size-normal">当您点击确认同意本政策，即表示您已充分阅读、理解且接受本政策的全部内容，并与融趣达成一致，本政策对您及融趣均具有法律约束力。</span>
</p>
<p className="sub_title">
<span className="font-size-normal">一、定义</span>
</p>
<p>
<span className="font-size-normal">1.融趣旗下产品及服务：指北京融趣科技有限公司通过下述途径向您提供的产品及服务：包括但不限于融趣网站、移动应用、客户端、相关微信开放平台账号或小程序以及供第三方网站和应用程序使用的融趣软件开发工具包（SDK）和应用程序编程接口（API）、未设独立隐私政策的融趣关联公司提供的产品及服务等。</span>
</p>
<p>
<span className="font-size-normal">2.其他第三方产品或服务：包括但不限于融趣以外的其他交易相对方、第三方网站以及第三方服务提供者为您提供的产品或服务。</span>
</p>
<p>
<span className="font-size-normal">3.合作伙伴：指融趣服务提供过程中涉及的第三方信息系统、支付机构、微信/QQ/微博等用于第三方登录的社交平台，以及融趣为实现部分功能、提供更好的客户服务和用户体验而进行合作的其他第三方主体或平台。</span>
</p>
<p>
<span className="font-size-normal">4.个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</span>
</p>
<p>
<span className="font-size-normal">5.个人敏感信息：是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。包括身份证件号码、手机号码、个人生物识别信息、银行账号、财产信息、行踪轨迹、交易信息、14岁以下（含）儿童的个人信息等。</span>
</p>
<p>
<span className="font-size-normal">6.个人信息删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。</span>
</p>
<p className="sub_title">
<span className="font-size-normal">二、用户权利义务</span>
</p>
<p>
<span className="font-size-normal">1.您理解并同意，享受融趣服务过程中，您需自行准备相关设备且承担如下费用：</span>
</p>
<p>
<span className="font-size-normal">1.1上网设备：包括并不限于电脑或者其他上网终端、调制解调器及其他必备的上网装置；</span>
</p>
<p>
<span className="font-size-normal">1.2上网开支：包括并不限于网络接入费、上网设备租用费、手机流量费等；</span>
</p>
<p>
<span className="font-size-normal">2.本协议所称用户或您，需具有享受融趣相关服务的民事行为及责任能力。如您为未成年人（包含十四周岁以下的儿童）或限制民事行为能力人、无民事行为能力人，请您务必在监护人或法定代理人陪同下阅读本协议并由监护人或法定代理人代理完成点击确认、同意行为。平台收集到点击确认、同意本政策操作记录即视为该同意行为系您的监护人或法定代理人作出且您及您的监护人或法定代理人已充分阅读、理解且接受本政策的全部内容，并与融趣达成一致，本政策对您及您的监护人或法定代理人、融趣均具有法律约束力。</span>
</p>
<p>
<span className="font-size-normal">3.您理解并保证，在使用本服务过程中所提供的资料均具有真实性、准确性，信息完整有效。如信息有变动的，应及时进行更新。如因前述原因导致您本人或第三人人身或财产受到损害，融趣不承担责任。</span>
</p>
<p>
<span className="font-size-normal">4.您理解并保证，您在享受融趣相关服务过程中，不违背中华人民共和国大陆地区相关法律法规之规定，不利用融趣服务从事非法行为。您将对您在融趣内发布的所有信息及言论承担所有责任。</span>
</p>
<p className="sub_title">
<span className="font-size-normal">三、平台责任</span>
</p>
<p>
<span className="font-size-normal">1.融趣严格按照本政策及《中华人民共和国网络安全法》、《个人信息安全规范》等相关法律法规的约定收集、使用、储存和分享您的个人信息，本政策对个人信息保护相关内容未作明确约定的，以《网络安全法》、《个人信息安全规范》等相关法律法规约定为准。除因融趣存在过错外，您自行承担使用融趣服务产生的风险。您如因本人过错或行为而遭受人身损害、财产损害，造成第三方人身损害、财产损害或由第三人造成您人身、财产损害的，应当依法向责任主体追究法律责任，融趣不承担相关责任。</span>
</p>
<p>
<span className="font-size-normal">2.融趣将会尽其商业上的合理努力保障您在融趣及服务中的数据存储安全，但是，融趣并不能就此提供完全保证您的个人信息不会受到任何侵害。融趣不对您在融趣及服务中相关数据的删除或储存失败负责，您可根据自身需求自行备份融趣及服务中的相关数据。</span>
</p>
<p>
<span className="font-size-normal">3.如您违反本政策，融趣保留暂停或终止为您提供融趣各项服务的权利，同时有权拒绝提供服务、关闭个人账号、清除或编辑内容、取消订单、删除各类不符合法律规定或不真实信息内容而无需通知用户。</span>
</p>
<p>
<span className="font-size-normal">4.您理解并同意，本政策提供的个人信息保护措施仅适用于融趣软件及相关服务。一旦您离开融趣及相关服务，浏览或使用其他网站、服务及内容资源，我们即没有能力及义务保护您在融趣软件及相关服务之外的软件、网站提交的任何个人信息，无论您登录、浏览或使用上述软件、网站是否基于融趣的链接或引导。</span>
</p>
<p>
<span className="font-size-normal">5.您理解并同意：在使用本服务的过程中，可能会遇到不可抗力、技术风险等因素，使本服务发生中断，对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更、数据丢失等情形，融趣在法律允许的最大范围内免责：</span>
</p>
<p>
<span className="font-size-normal">5.1&nbsp;因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素。</span>
</p>
<p>
<span className="font-size-normal">5.2&nbsp;您或融趣的电脑软件、系统、硬件出现故障或其他原因导致您无法使用融趣。</span>
</p>
<p>
<span className="font-size-normal">5.3因电力供应故障、通讯网络故障（包括但不限于电子通讯传达失败或延时、用于电子通讯的计算机程序对电子通讯的拦截或操纵）等公共服务因素或您自身因素（包括但不限于您操作不当、通过非融趣授权的方式使用本服务）或第三人因素（包括但不限于受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏、顾客的错误下单等错误操作）。</span>
</p>
<p>
<span className="font-size-normal">5.4在融趣已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、缺陷、网络信息与数据安全、技术风险等因素。</span>
</p>
<p>
<span className="font-size-normal">5.5&nbsp;您知悉并理解，融趣将最大程度履行合理的注意义务，维护融趣数据准确性，但由于互联网技术因素等客观原因，融趣可能会出现网页显示信息滞后或差错现象，融趣将在发现后尽快进行修复。</span>
</p>
<p>
<span className="font-size-normal">5.6&nbsp;其他融趣无法控制或合理预见的情形。</span>
</p>
<p>
<span className="font-size-normal">6.&nbsp;下列情形下，非因平台故意或过失行为，平台将不承担任何责任：</span>
</p>
<p>
<span className="font-size-normal">6.1&nbsp;由于您将用户密码告知他人或与他人共享注册账户，由此导致的任何个人资料泄露。</span>
</p>
<p>
<span className="font-size-normal">6.2&nbsp;任何由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营之不可抗力而造成的个人资料泄露、丢失、被盗用或被窜改等。</span>
</p>
<p>
<span className="font-size-normal">6.3&nbsp;由于与融趣相关的其他第三方产品或服务所造成之个人资料泄露及由此而导致的任何法律争议和后果。</span>
</p>
<p>
<span className="font-size-normal">7.&nbsp;融趣仅对因融趣原因给用户造成的直接、实际损失依法承担相应的赔偿责任，不对任何间接损失、惩罚性赔偿承担责任。</span>
</p>
<p className="sub_title">
<span className="font-size-normal">四、个人信息保护</span>
</p>
<p>
<span className="font-size-normal">本政策将帮助您了解以下内容：</span>
</p>
<p>
<span className="font-size-normal">1.平台如何收集和使用您的个人信息</span>
</p>
<p>
<span className="font-size-normal">2.平台如何使用Cookie和同类技术</span>
</p>
<p>
<span className="font-size-normal">3.平台如何共享、转让、公开披露您的个人信息</span>
</p>
<p>
<span className="font-size-normal">4.平台如何保护您的个人信息</span>
</p>
<p>
<span className="font-size-normal">5.平台如何处理未成年人的个人信息</span>
</p>
<p>
<span className="font-size-normal">6.您的个人信息如何在全球范围转移</span>
</p>
<p>
<span className="font-size-normal">7.您的权利</span>
</p>
<p>
<span className="font-size-normal">8.本政策如何更新</span>
</p>
<p className="sub_title">
<span className="font-size-normal">（一）平台如何收集和使用您的个人信息</span>
</p>
<p>
<span className="font-size-normal">1.&nbsp;收集信息类型及用途</span>
</p>
<p>
<span className="font-size-normal">平台会出于本政策所述的以下目的，收集和使用您的个人信息。除非为实现基本业务功能或按照相关法律法规必须收集，拒绝提供以下信息仅会使你无法使用相关特定功能，但不影响您正常使用融趣的其他功能：</span>
</p>
<p>
<span className="font-size-normal">1.1&nbsp;为向您提供平台相应功能，平台需收集的信息包括：</span>
</p>
<p>
<span className="font-size-normal">1.1.1账号信息：为成为融趣用户，便于平台为您提供服务，您需要提供基本注册或登录信息，包括手机号码、邮箱，并创建您的帐号、用户名和密码。</span>
</p>
<p>
<span className="font-size-normal">1.1.2个人资料和服务项目信息：在部分单项服务及注册登录过程中，如果您提供其他额外信息（您帐号所提供的年级、地区、昵称、性别、头像、笔迹、课内成绩与练习、课中视频），将有助于平台给您提供更优的服务体验，但如果您不提供这些信息，并不会影响您使用融趣的基本功能。</span>
</p>
<p>
<span className="font-size-normal">1.1.3验证信息：依照相关法律法规的要求或者特定功能及服务需要，您在使用特定功能及服务前，可能需要您提供姓名。如果您不提供上述信息，平台将不能向您提供相关的功能及服务。为避免歧义，如果您仅需使用浏览、搜索及查看融趣官方主页及融趣开放平台等基本服务及介绍，您不需要注册成为平台的会员及提供上述信息。</span>
</p>
<p>
<span className="font-size-normal">1.1.4&nbsp;“仅浏览模式”下收集的信息：在仅浏览模式下，我们可能会申请系统设备权限收集设备信息、日志信息，用于信息推送和安全风控；当您使用语音搜索时，我们会申请录音权限，并收集您的语音信息。</span>
</p>
<p>
<span className="font-size-normal">1.2为向您提供更契合您需求的服务，您会向平台提供信息并将这些信息进行关联，这些功能和信息包括：</span>
</p>
<p>
<span className="font-size-normal">1.2.1当您在融趣旗下平台上使用配送/邮寄（资料、图书购买）服务时，您需要提供取\收货人的姓名、性别（用于配送人员联络时称呼您，如刘女士）、手机号码、收货地址、门牌号，以便于您的订单能够安全准确送达并完成。您还可以选择为常用地址加注标签（如家、公司），以便于此后的查找使用。</span>
</p>
<p>
<span className="font-size-normal">1.2.2代他人购买平台服务时填写的信息：您可以通过融趣旗下平台为其他人购买各类产品/服务，并提供该实际服务接收人的有关信息，您同意并确保此前请您已取得相关人的授权。</span>
</p>
<p>
<span className="font-size-normal">1.2.3&nbsp;订单、支付信息：您在融趣旗下平台上支付时，您可以选择融趣合作的第三方支付机构（如微信支付、支付宝支付通道）所提供的支付服务。支付功能本身并不收集您的个人信息，但平台需要将您的融趣订单信息及对账信息与这些支付机构共享以确认您的支付指令并完成支付。</span>
</p>
<p>
<span className="font-size-normal">为展示您帐号的订单信息，平台会收集您在使用平台服务过程中产生的订单信息用于向您展示及便于您对订单进行管理。您在融趣生成的订单中，将包含您的身份信息、联络信息、地址信息、支付信息，这些都属于敏感信息，请您谨慎向他人展示或对外提供，如融趣需对外提供订单信息时，将取得您的授权，并尽到合理商业注意义务对您的信息进行去标识化处理以在最大化保护您的个人信息同时实现信息可用性。</span>
</p>
<p>
<span className="font-size-normal">1.2.4您在平台作出的评价和发布的内容：您在融趣旗下平台上主动对产品/服务进行评价或发布其他内容（如发布文字、语音、视频等信息）时，平台将收集您发布的信息，并展示您的昵称、头像和发布内容。您还可以通过主动上传图片、视频等方式授权访问您的相机、照片和麦克风，以便于您通过拍照、拍视频、上传照片或上传视频等方式发布内容。当您需要关闭该功能时，大多数移动设备都会支持您的这项需求，具体方法请参考或联系您的移动设备的服务商或生产商。</span>
</p>
<p>
<span className="font-size-normal">1.2.5&nbsp;客服服务相关的通信记录等信息：当您与平台的客服取得联系时，平台的系统会记录您与客服之间的通讯记录，以及使用您的帐号信息以便核验身份；当您需要平台提供与您订单相关的客户服务时，平台可能会查询您的相关订单信息以便给予您适当的帮助和处理；当您需要客服协助您修改有关信息（如配送地址、联系方式）时，您可能还需要提供上述信息外的其他信息以便完成修改。</span>
</p>
<p>
<span className="font-size-normal">1.2.6&nbsp;摄像头收集用户的相关信息：当您参与师生互动时，我们会要求您开启摄像头并获取您的画面信息，除此之外为了向您提供全程课堂学情监测服务，我们在师生互动之外也会获取您的摄像头拍摄画面，用于向家长提供学员每堂课的学习表现。如果您同意，则我方可以获取该画面。如果您不同意，请您在课堂开始之前的画面中选择“视频开启”予以关闭。同时，您也可以在上课过程中随时选择关闭全程检测功能。如果您选择关闭该功能，将无法获得“全程学情监测”服务。</span>
</p>
<p>
<span className="font-size-normal">1.3我们通过不断提升的技术手段加强安装在您的设备的软件的安全能力，以防您的个人信息泄露，为了保障向您提供的服务的安全稳定运营，预防病毒、木马程序和其他恶意程序、网站，我们会收集关于您使用产品、服务以及使用方式的信息并将这些信息进行关联，这些信息包括：</span>
</p>
<p>
<span className="font-size-normal">1.3.1设备信息：平台会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息。若您不提供设备相关信息，当您使用功能并遇到问题时，平台将无法及时排查到问题原因，但不会影响您使用融趣的其他功能。具体设备信息如下：</span>
</p>
<p>
<span className="font-size-normal">Android：包括设备型号、操作系统版本、设备设置、唯一设备标识符OAID&nbsp;及&nbsp;android&nbsp;ID&nbsp;、软硬件特征信息。</span>
</p>
<p>
<span className="font-size-normal">1.3.2日志信息：当您使用平台的网站或客户端提供的产品或服务时，平台会自动收集您对平台服务的详细使用情况，作为有关网络日志保存（您的搜索查询内容、IP地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间、您访问的网页记录、通话状态信息）。</span>
</p>
<p>
<span className="font-size-normal">请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果平台将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，平台会将该类个人信息做匿名化处理。</span>
</p>
<p>
<span className="font-size-normal">1.3.3第三方提供的与您交易、支付相关的信息：为能向您提供更优质的服务，同时为能确认交易状态及为您提供售后与争议解决服务，经您授权后平台会通过您选择的交易对象和交易直接涉及的第三方信息系统、支付机构等收集与交易进度相关的您的交易、支付相关信息，以便于平台处理您的订单并保证服务的顺利完成，或者更好地预防诈骗、刷单等恶意行为。</span>
</p>
<p>
<span className="font-size-normal">1.3.4第三方提供的您的身份信息、账号信息：平台会依据法律法规的要求以及与第三方的约定，以弹窗方式经您授权后从融趣的关联公司、合作伙伴获得您的有关信息，并对其信息来源的合法性进行确认后使用您的这些信息。如您在授权使用第三方帐号登录时，平台会从第三方获取您共享的帐号信息（如头像、昵称），并在您同意本政策后、在您使用相关功能时，将您的第三方帐号与融趣帐号进行绑定。</span>
</p>
<p>
<span className="font-size-normal">1.5为您提供安全保障</span>
</p>
<p>
<span className="font-size-normal">为预防、发现、调查欺诈、侵权、危害安全、非法或违反与平台或与平台关联公司的协议、政策或规则的行为，平台可能收集或整合您的用户信息、服务使用信息、设备信息、日志信息以及平台关联公司、合作伙伴取得您授权或依据法律共享的信息。</span>
</p>
<p>
<span className="font-size-normal">1.6&nbsp;其他</span>
</p>
<p>
<span className="font-size-normal">融趣收集及使用上述信息的目的是为了更好地经营融趣产品和服务，平台将基于本政策未载明的其他特定目的收集您的信息时，会事先征求您的同意。如平台停止运营融趣产品或服务，平台将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。</span>
</p>
<p>
<span className="font-size-normal">1.7&nbsp;征得授权同意的例外</span>
</p>
<p>
<span className="font-size-normal">根据相关法律法规规定及国家标准，以下情形中，我们可能会依法收集并使用您的个人信息无需征得您的同意:</span>
</p>
<p>
<span className="font-size-normal">(1)与个人信息控制者履行法律法规规定的义务相关的;</span>
</p>
<p>
<span className="font-size-normal">(2)与国家安全、国防安全直接相关的;</span>
</p>
<p>
<span className="font-size-normal">(3)与公共安全、公共卫生、重大公共利益直接相关;</span>
</p>
<p>
<span className="font-size-normal">(4)与刑事侦查、起诉、审判和判决执行等直接相关的;</span>
</p>
<p>
<span className="font-size-normal">(5)出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的;</span>
</p>
<p>
<span className="font-size-normal">(6)所涉及的个人信息是个人信息主体自行向社会公众公开的;</span>
</p>
<p>
<span className="font-size-normal">(7)从合法公开披露的信息中收集个人信息的，如:合法的新闻报道、政府信息公开等渠道;</span>
</p>
<p>
<span className="font-size-normal">(8)根据个人信息主体的要求签订和履行合同所必须的;</span>
</p>
<p>
<span className="font-size-normal">注：个人信息保护政策的主要功能为公开个人信息控制者收集、使用个人信息范围和规则，不宜将其视为合同。</span>
</p>
<p>
<span className="font-size-normal">(9)用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现或处置产品或服务的故障;</span>
</p>
<p>
<span className="font-size-normal">(10)法律法规规定的其他情形。</span>
</p>
<p>
<span className="font-size-normal">2.&nbsp;信息使用方式</span>
</p>
<p>
<span className="font-size-normal">收集您的信息是为了向您提供服务及提升服务质量，为了实现这一目的，您的信息将用于下列用途，如平台需将收集到的相关信息用于新的目的、范围或实际传输方式与本协议约定不一致时，平台将以弹窗提示方式通知到您并取得您的明示同意：</span>
</p>
<p>
<span className="font-size-normal">2.1向您提供您使用的融趣产品或服务，并维护、改进、优化这些服务及服务体验；</span>
</p>
<p>
<span className="font-size-normal">2.2为预防、发现、调查欺诈、侵权、危害安全、非法或违反与平台或与平台关联公司的协议、政策或规则的行为，保护您、其他用户或公众，平台或平台关联公司的合法权益，平台可能使用或整合您的用户信息、服务使用信息、设备信息、日志信息以及平台关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账号及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施；</span>
</p>
<p>
<span className="font-size-normal">2.3为向您提供更契合您需求的服务内容，我们会出于以下目的，收集和使用您的信息</span>
</p>
<p>
<span className="font-size-normal">2.3.1帮助您成为“融趣”的用户</span>
</p>
<p>
<span className="font-size-normal">为使用“融趣”学习平台，您需要首先成为“融趣”注册用户，以便我们为您提供会员服务，您需要提供注册、登录融趣账号使用的电话号码，用户自行填写的姓名或昵称、头像、年级&nbsp;，并创建“融趣”学习平台账号和密码。在您主动注销账号后，我们将跟进法律对您的个人信息进行匿名化处理</span>
</p>
<p>
<span className="font-size-normal">2.3.2向您提供产品或服务</span>
</p>
<p>
<span className="font-size-normal">（1）用户体验改进计划</span>
</p>
<p>
<span className="font-size-normal">融趣将基于您对“融趣”使用数据，结合个人常用设备信息进行统计，来诊断系统问题，优化产品体验。我们会使用工具（含第三方工具）统计您在使用融趣产品过程中产品的数据信息，其中包含：应用启动数，页面访问数，重点按钮点击数，设置项的配置属性。随着融趣产品后续版本升级，我们的统计范围会有所变化，您的下载安装使用融趣后续版本的行为，将视为您届时对相关改变的同意。</span>
</p>
<p>
<span className="font-size-normal">（2）开展营销活动</span>
</p>
<p>
<span className="font-size-normal">当您选择参与我们举办的有关营销活动时，根据活动需要可能使用您提供个人信息，与第三方支付服务账号。但是这些信息是您收到转账和礼品所必要的。如果您拒绝提供这些信息，我们将可能无法向您转账和发放礼品。</span>
</p>
<p>
<span className="font-size-normal">（3）客服与售后</span>
</p>
<p>
<span className="font-size-normal">我们的客服和售后功能会使用你的账号信息为您提供服务。当我们与您互相建立联系时，我们可能会保存您的通信或通话记录和内容或您留下的联系方式，以便帮您解决问题，或记录相关问题的处理方案及结果。我们客服会使用您的账号信息与您核验您的身份。您有可能会在与我们的客服人员沟通时，提供给出上述信息以外的其他信息。</span>
</p>
<p>
<span className="font-size-normal">2.6&nbsp;目前，融趣不会通过除上述平台获取信息方式及第三方SDK获取您的个人信息。如未来为业务发展需要从其他第三方渠道间接获取您的个人信息，我们会在获取前向您明示您个人信息的来源、类型及使用范围，如开展业务需进行的信息处理活动超出您原本向第三方提供个人信息时的授权同意范围，我们将在处理您的该等个人信息前，征得您的明示同意；此外，我们也将会严格遵守相关法律法规的规定，并要求第三方保障其提供的信息的合法性。</span>
</p>
<p>
<span className="font-size-normal">2.7经您许可的其他用途。</span>
</p>
<p>
<span className="font-size-normal">3.&nbsp;信息的储存</span>
</p>
<p>
<span className="font-size-normal">平台将以以下形式储存收集到的相关个人信息：以结构化数据存储在中国人民共和国境内，不会向境外转移。同时只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的存留要求。例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。就平台服务所涉及的用户行为日志，我们将进行匿名化处理。平台判断前述期限的标准包括：</span>
</p>
<p>
<span className="font-size-normal">3.1完成与您相关的交易目的、维护相应交易及业务记录、应对您可能的查询或投诉；</span>
</p>
<p>
<span className="font-size-normal">3.2保证平台为您提供服务的安全和质量；</span>
</p>
<p>
<span className="font-size-normal">3.3您是否同意更长的留存期间；</span>
</p>
<p>
<span className="font-size-normal">3.4是否存在保留期限的其他特别约定。</span>
</p>
<p>
<span className="font-size-normal">在您的个人信息超出保留期间后，平台会根据适用法律的要求删除您的个人信息，或使其匿名化处理（如您为未成年人，在您的个人信息超出保留期间后，平台会按照相关法律法规要求对您的个人信息进行相应处理）。如因特殊情形无法按照相关要求删除的，平台将对该部分事项向您进行说明。</span>
</p>
<p className="sub_title">
<span className="font-size-normal">（二）平台如何使用Cookie和同类技术</span>
</p>
<p>
<span className="font-size-normal">1.为使您获得更轻松的访问体验，您使用融趣产品或服务时，平台可能会通过采用各种技术收集和存储您访问融趣服务的数据文件，在您访问或再次访问融趣服务时,平台能识别您的身份，并通过分析数据为您提供更好更多的服务。包括使用小型数据文件识别您的身份，这么做是为了解您的使用习惯，帮您省去重复输入账号信息的步骤，或者帮助判断您的账号安全。这些数据文件可能是Cookie、Flash&nbsp;Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。</span>
</p>
<p>
<span className="font-size-normal">2.请您理解，平台的某些服务只能通过使用Cookie才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝融趣的Cookie，但拒绝融趣的Cookie在某些情况下您可能无法使用依赖于cookies的融趣服务的部分功能。</span>
</p>
<p>
<span className="font-size-normal">3.网页上常会包含一些电子图像，称为“单像素&nbsp;GIF&nbsp;文件”或“网络&nbsp;beacon”，它可以帮助网站计算浏览网页的用户或访问某些cookie。平台会通过网络beacon收集您浏览网页活动信息，包括您访问的页面地址、您先前访问的援引页面的位址、您停留在页面的时间、您的浏览环境以及显示设定。</span>
</p>
<p className="sub_title">
<span className="font-size-normal">（三）平台如何共享、转让、公开披露您的个人信息</span>
</p>
<p>
<span className="font-size-normal">1.&nbsp;共享</span>
</p>
<p>
<span className="font-size-normal">我们承诺对您的信息进行严格保密，平台不会与融趣服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：</span>
</p>
<p>
<span className="font-size-normal">1.1在获取明确同意的情况下共享：获得您的明确同意后，平台会与其他方共享您的个人信息。</span>
</p>
<p>
<span className="font-size-normal">1.2在法定情形下的共享：平台可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。</span>
</p>
<p>
<span className="font-size-normal">如果平台共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。您同意关联公司与融趣共享服务必要的相关信息。融趣将准确记录和保存个人信息的共享、转让的情况，包括共享、转让的日期、规模、目的，以及数据接收方基本情况。</span>
</p>
<p>
<span className="font-size-normal">1.3与授权合作伙伴共享：仅为实现本政策中声明的目的，平台的某些服务将由平台和授权合作伙伴共同提供。平台可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。平台仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。如果为了向您提供服务而需要将您的信息共享至第三方，我们将评估该第三方收集信息的合法性、正当性、必要性。我们将要求第三方对您的信息采取保护措施，并且严格遵守相关法律法规与监管要求。平台的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。</span>
</p>
<p>
<span className="font-size-normal">如果为了向您提供服务而需要将您的信息共享至第三方，我们将评估该第三方收集信息的合法性、正当性、必要性。我们将要求第三方对您的信息采取保护措施，并且严格遵守相关法律法规与监管要求。平台的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。</span>
</p>
<p>
<span className="font-size-normal">1.4融趣服务含有到其他网站的链接。除法律另有规定外，融趣对其他网站的隐私保护措施不负任何责任。平台可能在任何需要的时候增加商业伙伴或共用品牌的网站，但是提供给他们的将仅是匿名化的信息，平台将不会公开您的身份。</span>
</p>
<p>
<span className="font-size-normal">2.&nbsp;委托处理</span>
</p>
<p>
<span className="font-size-normal">为实现本隐私权政策中声明的目的，我们的某些服务将由外部供应商提供，例如在您上网采购网站上的产品时，平台必须向物流服务提供商提供您的个人信息才能安排送货，安排合作伙伴委托处理，或者安排合作伙伴提供服务。平台仅会出于合法、正当、必要、特定、明确的目的提供您的个人信息，并且只会共享提供服务所必要的个人信息。</span>
</p>
<p>
<span className="font-size-normal">对我们委托处理个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的要求、本个人信息保护政策以及其他任何相关的保密和安全措施来处理个人信息。</span>
</p>
<p>
<span className="font-size-normal">3.&nbsp;转让</span>
</p>
<p>
<span className="font-size-normal">平台不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</span>
</p>
<p>
<span className="font-size-normal">3.1在获取明确同意的情况下转让：获得您的明确同意后，平台会向其他方转让您的个人信息。</span>
</p>
<p>
<span className="font-size-normal">3.2在融趣服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，平台会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则平台将要求该公司、组织和个人重新向您征求授权同意。</span>
</p>
<p>
<span className="font-size-normal">4.&nbsp;公开披露</span>
</p>
<p>
<span className="font-size-normal">平台仅会在以下情况下，公开披露您的个人信息：</span>
</p>
<p>
<span className="font-size-normal">4.1获得您明确同意或基于您的主动选择，平台可能会公开披露您的个人信息；</span>
</p>
<p>
<span className="font-size-normal">4.2如果平台确定您出现违反法律法规或严重违反融趣相关协议规则的情况，或为保护融趣及其关联公司用户或公众的人身财产安全免遭侵害，平台可能依据法律法规或融趣相关协议规则征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及融趣已对您采取的措施。</span>
</p>
<p>
<span className="font-size-normal">5.&nbsp;共享、转让、公开披露个人信息时事先征得授权同意的例外</span>
</p>
<p>
<span className="font-size-normal">以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</span>
</p>
<p>
<span className="font-size-normal">5.1与个人信息控制者履行法律法规规定的义务相关的；</span>
</p>
<p>
<span className="font-size-normal">5.2与国家安全、国防安全直接相关的；</span>
</p>
<p>
<span className="font-size-normal">5.3与公共安全、公共卫生、重大公共利益直接相关的；</span>
</p>
<p>
<span className="font-size-normal">5.4与刑事侦查、起诉、审判和判决执行等直接相关的；</span>
</p>
<p>
<span className="font-size-normal">5.5出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</span>
</p>
<p>
<span className="font-size-normal">5.6个人信息主体自行向社会公众公开的个人信息；</span>
</p>
<p>
<span className="font-size-normal">5.7从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</span>
</p>
<p>
<span className="font-size-normal">6.&nbsp;共享、转让、公开披露个人信息时的责任承担</span>
</p>
<p>
<span className="font-size-normal">我们仅会出于正当、必要、特定的目的共享、转让或公开披露您的信息。平台的合作伙伴无权将共享、转让或公开披露的个人信息用于与产品或服务无关的其他用途。我们会要求他们履行相关保密义务并采取相应的安全措施。融趣会通过合同等形式与第三方共同确定个人信息安全要求，并且在个人信息安全方面承担相应的责任和义务。信息接收方对您的信息仅有有限的访问权，他们还需承担合同义务，包括不限于使用合法合规的方式获取您的信息，采取合理的安全措施保护您的信息，根据法律规定及与我们的约定承担相应的法律责任。</span>
</p>
<p className="sub_title">
<span className="font-size-normal">（四）平台如何保护您的个人信息</span>
</p>
<p>
<span className="font-size-normal">1.平台会采取各种预防措施来保护您的个人信息，以保障您的个人信息免遭丢失、盗用和误用，以及被擅自取阅、披露、更改或销毁。为确保您个人信息的安全，平台有严格的信息安全规定和流程，并有专门的信息安全团队在公司内部严格执行上述措施。</span>
</p>
<p>
<span className="font-size-normal">2.融趣有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。</span>
</p>
<p>
<span className="font-size-normal">3.平台会采取合理可行的措施，尽力避免收集无关的个人信息，并在限于达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期限或在法律的允许期限内。</span>
</p>
<p>
<span className="font-size-normal">4.互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</span>
</p>
<p>
<span className="font-size-normal">5.如发生个人信息安全事件后，平台将按照法律法规的要求并最迟不迟于30&nbsp;个自然日内向您告知：安全事件的基本情况和可能的影响、平台已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况平台将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，平台会采取合理、有效的方式发布公告。同时，平台还将按照监管部门要求，上报个人信息安全事件的处置情况。</span>
</p>
<p>
<span className="font-size-normal">6.&nbsp;由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。请您知悉并理解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码等个人信息透露给他人。</span>
</p>
<p className="sub_title">
<span className="font-size-normal">（五）平台如何处理未成年人的个人信息</span>
</p>
<p>
<span className="font-size-normal">1.&nbsp;平台重视未成年人的个人信息保护，如您为未满18周岁的未成年人，建议您请您的父母或监护人仔细阅读本政策，并在征得您的父母或监护人同意的前提下使用平台的服务或向平台提供信息。对于经父母或监护人同意使用平台的产品或服务而收集未成年人个人信息的情况，平台只会在法律法规允许，父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。</span>
</p>
<p>
<span className="font-size-normal">2.&nbsp;特别地，如果您为未满14周岁的儿童，请您通知您的父母或监护人仔细阅读本政策并在您使用平台服务、提交个人信息之前，寻求您的父母或监护人同意和指导。只有在征得您的父母或监护人对本政策的同意后，14周岁以下的儿童方可使用平台提供的功能或服务。为加强对儿童个人信息的保护，除遵循本政策项下的其他规定外，平台将遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则，根据国家相关法律法规及本政策的规定保护儿童的个人信息，具体内容如下：</span>
</p>
<p>
<span className="font-size-normal">2.1平台就所收集的儿童信息和儿童以外的用户个人信息分别存储，平台将采取不低于同行业水平的加密措施存储儿童信息，确保儿童信息安全。</span>
</p>
<p>
<span className="font-size-normal">2.2&nbsp;平台将指定专人负责儿童信息保护，任何工作人员访问儿童信息的，必须经儿童信息保护人员的书面审批（包括电子邮件、短信、微信等电子信息交互方式），并形成访问情况的书面记录；同时，采取不低于同行业水平的技术措施，避免违法复制、下载儿童信息。</span>
</p>
<p>
<span className="font-size-normal">2.3平台向第三方转移儿童信息的，除再次征得儿童的父母或监护人的同意及满足上述原则外，将自行或者委托第三方机构进行安全评估，并形成书面评估报告。</span>
</p>
<p>
<span className="font-size-normal">2.4&nbsp;未经儿童父母或监护人同意而收集、使用儿童信息的，在接到其监护人的通知后，平台会尽快删除相关信息。</span>
</p>
<p className="sub_title">
<span className="font-size-normal">（六）您的个人信息如何在全球范围转移</span>
</p>
<p>
<span className="font-size-normal">平台在中华人民共和国境内运营中收集和产生的个人信息，存储在中华人民共和国境内，不会向境外转移。就中华人民共和国境外融趣用户使用融趣产品及服务过程中产生的个人信息，您同意回传存储在中华人民共和国境内的服务器上，否则您无法使用现有融趣的产品及服务。在此类情况下，平台会确保您的个人信息得到在中华人民共和国境内足够同等的保护。</span>
</p>
<p className="sub_title">
<span className="font-size-normal">（七）本政策如何更新</span>
</p>
<p>
<span className="font-size-normal">融趣有权在必要时修改本政策条款，并以应用内部弹窗方式通知到您。您可以随时在本平台查阅修改后的最新版本政策。如您不同意修改后的政策，您有权终止对平台的授权。本政策更新后，如果您继续使用相应平台功能，即视为您已接受修改后的政策。</span>
</p>
<p>
<span className="font-size-normal">对于重大变更，平台还会提供更为显著的通知（包括平台会通过应用内部弹窗的方式进行意见征集、公告通知甚至向您提供弹窗提示）。</span>
</p>
<p>
<span className="font-size-normal">本政策所指的重大变更包括但不限于：</span>
</p>
<p>
<span className="font-size-normal">1.&nbsp;平台的服务模式发生重大变化。如处理用户信息的目的、用户信息的使用方式等；</span>
</p>
<p>
<span className="font-size-normal">2.&nbsp;平台在控制权、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</span>
</p>
<p>
<span className="font-size-normal">3.&nbsp;用户信息共享、转让或公开披露的主要对象发生变化；</span>
</p>
<p>
<span className="font-size-normal">4.&nbsp;平台负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时；</span>
</p>
<p>
<span className="font-size-normal">5.&nbsp;用户信息安全影响评估报告表明存在高风险时。</span>
</p>
<p>
<span className="font-size-normal">我们还会将本政策的旧版本存档，供您查阅。</span>
</p>
<p className="sub_title">
<span className="font-size-normal">五、知识产权</span>
</p>
<p>
<span className="font-size-normal">1.&nbsp;融趣及其关联公司是本平台的知识产权权利人。本平台的一切著作权、商标权、专利权、商业秘密等知识产权，以及与本平台相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国大陆地区法律法规和相应的国际条约保护，融趣或其关联公司享有上述知识产权，但相关权利人依照法律规定应享有的权利除外。</span>
</p>
<p>
<span className="font-size-normal">2.&nbsp;未经平台书面同意，上述资料均不得在任何媒体直接或间接发布、播放、出于播放或发布目的而改写或再发行,&nbsp;或者被用于其他任何商业目的。所有以上资料或资料的任何部分仅可作为私人和非商业用途保存。</span>
</p>
<p className="sub_title">
<span className="font-size-normal">六、其他</span>
</p>
<p>
<span className="font-size-normal">1.本政策所有条款的标题仅为阅读方便，不作为本政策涵义解释或限制的依据。</span>
</p>
<p>
<span className="font-size-normal">2.本政策任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本政策其余条款的有效性及可执行性。</span>
</p>
<p>
<span className="font-size-normal">3.本政策可能存在包括中文、英文在内的多种语言的版本，如果存在中文版本与其他语言的版本相冲突的地方，以中文版本为准。</span>
</p>
<p>
<span className="font-size-normal">4.针对某些特定的产品及服务，平台还将制定特定的隐私政策，并在向您提供这些特定的产品及服务之前加以说明。如相关特定的隐私政策与本政策不一致的，适用该特定隐私政策。</span>
</p>
<p className="sub_title">
<span className="font-size-normal">七、法律适用与管辖</span>
</p>
<p>
<span className="font-size-normal">1.本政策之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律。如法律无相关规定的，参照商业惯例及/或行业惯例。</span>
</p>
<p>
<span className="font-size-normal">2.您因使用融趣服务所产生及与融趣服务有关的争议，由融趣与您协商解决。协商不成时，任何一方均可向被告所在地人民法院提起诉讼。</span>
</p>
<p className="sub_title">
<span className="font-size-normal">八、与平台联系</span>
</p>
<p>
<span className="font-size-normal">当您有个人信息相关问题或其他的投诉、建议等，可以通过如下方式与平台联系，平台将尽快审核所涉及内容，并于15个工作日内对于您的问题、投诉、建议进行回复：</span>
</p>
<p>
<span className="font-size-normal">平台运营主体名称：北京融趣科技有限公司</span>
</p>
</div>
    </div>
  );
}
export default Yinsi;
