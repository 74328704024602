import ErrorBoundary from "@component/ErrorBoundary";
import React, { lazy, Suspense } from "react";
import { createHashRouter } from "react-router-dom";
import Yinsi from "@page/yinsi";
import YingYe from "@page/yingyezhizhao";
const Home = lazy(() => import("@page/home"));
const HomeMain = lazy(() => import("@page/main"));
const Contact = lazy(() => import("@page/contact"));
const router = createHashRouter([
  {
    path: "/",
    element: <Home />,
    children: [
      {
        path: "/",
        element: (
          <Suspense fallback={<div></div>}>
            <HomeMain />
          </Suspense>
        ),
      },
      {
        path: "/contact",
        element: (
          <Suspense  fallback={<div></div>}>
            <Contact />
          </Suspense>
        ),
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/yinsi",
    element: <Yinsi />,
  },
  {
    path: "/yingyezhizhao",
    element: <YingYe />,
  }
]);

export default router;
