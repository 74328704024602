import { createModel } from '@rematch/core'
import { RootModel } from '@model/index'

export const home = createModel<RootModel>()({
    state: 0, // initial state
    reducers: {
        // handle state changes with pure functions
        increment(state, payload: number) {
            return state + payload
        },
    },
    effects: (dispatch) => ({
        // handle state changes with impure functions.
        // use async/await for async actions
        async hi(payload: number, state) {
            console.log('demo effect', payload, state);
        },
    }),
});