import * as React from 'react';
/**
 * 用于获取该组件内部React组件实例内部的异常信息
 * !注：只能获取该组件内部React组件的异常，不能获取到内部div等HTMLElement中的的异常错误
 */

interface Props {
    children?: React.ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error: any) {
      // Update state so the next render will show the fallback UI.
      console.log(error);
      return { hasError: true };
    }
  
    componentDidCatch(error: any, info: any) {
      // You can also log the error to an error reporting service
      console.log(error, info);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <h1>Something went wrong.</h1>;
      }
  
      return this.props.children; 
    }
  }
  
  export default ErrorBoundary;